import {
  icon_fa_photo_film,
  icon_fa_upload,
} from "assets/svgs/fontawesomeSvgs";
import ButtonOverlayStyle from "./buttonOverlayStyle";

/**
 * Adds hover effect with upload svg and optional other JSX
 * @param {Object} props
 * @param {JSX} props.children optional other buttons, etc.
 * @param {boolean} props.showIcons
 * @returns
 * @example
  <div className="button-overlay-wrapper">
    <ButtonOverlay>
      <CmsIconButton {...someProps} />
      <CmsPopoverButton {...someProps}>
        <div>someStuff</div>
      </CmsPopoverButton>
    </ButtonOverlay>
    <CmsResponsiveImage {...someProps} />
  </div>
 */
const ButtonOverlay = (props) => {
  return (
    <>
      <div className="icon-overlay">
        <div className="top-buttons">{props.children}</div>
        {props.showIcons ? 
          props.useMediaLibrary ? (
            <div className="upload-icon">
              {icon_fa_photo_film("svg-fill-white", 60)}
            </div>
          ) : (
            <div className="upload-icon">
              {icon_fa_upload("svg-fill-white", 60)}
            </div>
          )
        : null
      }
      </div>
      <ButtonOverlayStyle />
    </>
  );
};

export default ButtonOverlay;
