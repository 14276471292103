import { icon_fa_trash } from "assets/svgs/fontawesomeSvgs";
import CmsManagedImage from "components/cms/cmsManagedImage/cmsManagedImage";
import ButtonOverlay from "components/util/buttonOverlay/buttonOverlay";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteFileEditPage, editCollectionTypeField } from "store/actions";

// NOTE: import contentmanager only components dynamic
const CmsIconButton = dynamic(() =>
  import("components/cms/contentManagerControls/cmsIconButton/cmsIconButton")
);
const CmsContentImageMediaLibrary = dynamic(() =>
  import(
    "components/cms/cmsContent/cmsContentImageMediaLibrary/cmsContentImageMediaLibrary"
  )
);

/**
 * CmsContentManagedImage
 * Component for updating/displaying (editmode/editview) images from the MediaManager.
 *
 *
 * @param {object} mimg The mimg-Object with the image in .file. with width and height
 * @param {string} alt alt text for the img
 * @param {number | string} maxWidth in px as number or % as string e.g. {100} or {"100%"}
 * @param {number | object} maxHeight in px as number or object e.g. {mobile: 100,tablet: 100, desktop: 100, k: 100}
 * @param {number} maxFileSize max possible filesize defaults to 1
 * @param {string} contentType news | not set
 * @param {string} cmsPosition needed for redux store update
 * @param {string} cmsFieldImage needed for redux store image update
 * @param {string} cmsFieldAlt needed for redux store text update
 * @param {String} objectFit cover or contain
 * @param {boolean} useMediaLibrary optional opens the media library
 * @param {boolean} mediaLibrarySetOnlyFile optional sets the file object instead of the media library object
 * @param {Array} mediaLibraryTypes optional media library types that will be shown in the
 *  media library modal example [MANAGED_FILE_TYPE_JOBOFFER] (look into constants)
 */
// eslint-disable-next-line react/display-name
const CmsContentManagedImage = React.memo((props) => {
  const { t: tCms } = useTranslation("cms");
  const editView = useSelector((state) => state.cms.editView);
  const dispatch = useDispatch();

  const removeManagedImage = (pos, fieldname) => {
    if (props.contentType) {
      dispatch(
        editCollectionTypeField(
          props.contentType,
          fieldname,
          null,
          pos,
          props.nestedPosition
        )
      );
    } else {
      // default case no props.contentType given -> Contentpage content array
      dispatch(deleteFileEditPage(pos, fieldname, null));
    }
  };

  return (
    <>
      {editView && !props.disableEditView ? (
        <div className="cms-content-managed-image button-overlay-wrapper edit-view">
          <ButtonOverlay
            useMediaLibrary={true}
            showIcons={props.mimg ? true : false}
          >
            <CmsIconButton
              key="deleteImageButton"
              title={tCms("remove")}
              onClick={() =>
                removeManagedImage(props.cmsPosition, props.cmsFieldImage)
              }
              icon={<>{icon_fa_trash("svg-fill-white", 30)}</>}
              cssPosBottom="5px"
            />
          </ButtonOverlay>
          <CmsContentImageMediaLibrary
            // mimg only!
            img={props.mimg}
            cmsPosition={props.cmsPosition}
            cmsFieldImage={props.cmsFieldImage}
            // TBD: s.o.
            contentType={props.contentType}
            objectFit={props.objectFit}
            maxWidth={props.maxWidth}
            maxHeight={props.maxHeight}
            height={props.height}
            width={props.width}
            noImageSelectedHeight={props.noImageSelectedHeight}
            noImageSelectedMinHeight={props.noImageSelectedMinHeight}
            noImageSelectedWidth={props.noImageSelectedWidth}
            nestedPosition={props.nestedPosition}
            // TBD: This needs to be always false (or removed) from now on.
            mediaLibrarySetOnlyFile={false}
            managedFileCategories={
              props.managedFileCategories ? props.managedFileCategories : []
            }
          />
        </div>
      ) : (
        <div className="cms-content-managed-image">
          {props.mimg ? (
            <CmsManagedImage
              img={props.mimg}
              objectFit={props.objectFit}
              maxWidth={props.maxWidth}
              maxHeight={props.maxHeight}
              width={props.width}
              height={props.height}
            />
          ) : null}
        </div>
      )}
      <style jsx>{`
        .cms-content-managed-image {
          position: relative;
          &.edit-view {
            :global(.svg-fill-white) {
              filter: drop-shadow(0px 0px 4px black);
            }
            :hover {
              cursor: pointer;
            }
          }
        }

        /* Cms: .no-image-selected */
        /* Cards: */
        .cms-content-managed-image :global(.no-image-selected) {
          width: ${!props.maxWidth ? "50%" : props.width};
          max-width: ${!props.maxWidth && !props.noImageSelectedWidth
            ? "125px"
            : props.maxWidth} !important;
        }

        /* Login */
        :global(
            .login-content-element-container .login-caption .no-image-selected
          ) {
          height: ${props.height && props.height === "auto" && !props.maxWidth
            ? "unset"
            : props.height} !important;
        }
      `}</style>
    </>
  );
});

export default CmsContentManagedImage;
