import dynamic from "next/dynamic";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editCollectionTypeField, updateEditPageCEField } from "store/actions";
import CmsContentCaptionStyle from "./cmsContentCaptionStyle";

// NOTE: import contentmanager only components dynamic
const CmsMaxLengthIndicator = dynamic(() =>
  import(
    "components/cms/contentManagerControls/cmsMaxLengthIndicator/cmsMaxLengthIndicator"
  )
);

const CmsContentCaption = (props) => {
  const editView = useSelector((state) => state.cms.editView);
  const dispatch = useDispatch();
  const captionTextInputRef = useRef(null);

  const handleChange = (e) => {
    e.target.value = e.target.value.replace(/(\r\n|\n|\r)/gm, "");

    if (props.contentType) {
      dispatch(
        editCollectionTypeField(
          props.contentType,
          props.cmsField,
          e.target.value,
          props.cmsPosition
        )
      );
    } else {
      // default case no props.contentType given -> Contentpage content array
      dispatch(
        updateEditPageCEField(props.cmsPosition, props.cmsField, e.target.value)
      );
    }
  };

  return (
    <>
      {editView && !props.disableEditView ? (
        <>
          {!props.captionHidden ? (
            <div className="with-max-length-indicator">
              <div
                className="grow-wrap cms-content-caption-wrapper"
                data-replicated-value={props.content}
              >
                <textarea
                  ref={captionTextInputRef}
                  maxLength={250}
                  rows="1"
                  onInput={(e) => {
                    e.target.parentNode.dataset.replicatedValue =
                      e.target.value;
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  className={`${props.center ? "center" : ""} ${
                    props.content === "" || props.content === null
                      ? "no-caption"
                      : ""
                  } cms-content-caption`}
                  type="text"
                  defaultValue={props.content}
                  placeholder={!props.content ? props.cmsPlaceholder : null}
                  onChange={handleChange}
                />
              </div>
              <CmsMaxLengthIndicator
                margin={"0px"}
                fontSize={"10px"}
                maxLength={250}
                length={captionTextInputRef.current?.value.length}
              />
            </div>
          ) : null}
        </>
      ) : (
        <>
          {props.content && !props.captionHidden ? (
            <figcaption
              className={`${props.center ? "center " : ""}cms-content-caption`}
            >
              {props.content}
            </figcaption>
          ) : null}
        </>
      )}
      <CmsContentCaptionStyle />
    </>
  );
};

export default CmsContentCaption;
