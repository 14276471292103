import CmsContentCaption from "components/cms/cmsContent/cmsContentCaption/cmsContentCaption";
import CmsContentManagedImage from "components/cms/cmsContent/cmsContentManagedImage/cmsContentManagedImage";
import { useTranslation } from "next-i18next";
import { Col, Row } from "react-bootstrap";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { MANAGED_FILECATEGORIES } from "utils/constants";
import {
  contentElementBGColor,
  getCssMaxWidthValue,
  getObjectFitValue,
  getRemValue,
  optimizedImage,
  searchableID,
} from "utils/util";
import ImageContentElementSpace from "./imageContentElementSpace";
import AnimationWrapper from "components/util/animationWrapper/animationWrapper";

/**
 * ImageContentElement
 *
 **/
const ImageContentElement = (props) => {
  const { t: tCms } = useTranslation("cms");
  // maxHeights for the Image. (with fallback-Values)
  const maxHeights = {
    mobile: props.settings.maxHeightImagePXMobile || 200,
    tablet: props.settings.maxHeightImagePXTablet || 300,
    desktop: props.settings.maxHeightImagePXDesktop || 400,
    wqhd: props.settings.maxHeightImagePX2k || 400,
  };

  return (
    <>
      <div
        id={`anchor-${searchableID(props.content)}`}
        className={`${
          props.settings.spaceX === 0 ? "" : "container-fluid"
        } image-content-element-container content-element ${
          props.content.cfgCustomClassName || ""
        } ${props.isLastElement ? "last-element" : ""} ${
          props.isFirstElement ? "first-element" : ""
        }`}
      >
        <Row
          id={
            props.content.cfgAnchorName
              ? `anchor-${props.content.cfgAnchorName}`
              : ""
          }
          className="g-0"
        >
          <Col xs={12}>
            <AnimationWrapper
              animationIn={props.content.cfgAnimationIn}
              animationOut={props.content.cfgAnimationOut}
            >
              <div className="image">
                <figure>
                  <CmsContentManagedImage
                    mimg={optimizedImage(
                      props.content.mimg,
                      props.isMobile,
                      props.content.cfgImageIgnoresMaxWidth
                        ? null
                        : props.settings.maxWidthImagePX,
                      props.content.cfgUseHighestImageQuality
                    )}
                    cmsPosition={props.position}
                    cmsFieldImage="mimg"
                    maxWidth={getCssMaxWidthValue(
                      props.settings.maxWidthImagePX,
                      props.content.cfgImageIgnoresMaxWidth
                    )}
                    // CssAttribute-Props without (!) CssUnit.
                    // TODO: This prop (6-1-6) should be removed in the future.
                    // 6-1-6 should never be the dimension of any image (atm) if you inspect it in the browser!
                    // If you see 6-1-6 this would mean: No setting + fallback-Value exist OR selector does not work.
                    maxHeight={616}
                    height="auto"
                    width="100%"
                    managedFileCategories={[MANAGED_FILECATEGORIES.IMAGE]}
                  />
                  {!props.content.cfgHideCaption ? (
                    <CmsContentCaption
                      content={props.content.imgManagedCaption}
                      cmsPosition={props.position}
                      cmsField="imgManagedCaption"
                      cmsPlaceholder={tCms("enterCaptionHere")}
                      captionHidden={props.content.cfgHideCaption}
                    />
                  ) : null}
                </figure>
              </div>
            </AnimationWrapper>
          </Col>
        </Row>
      </div>
      <ImageContentElementSpace settings={props.settings} />
      <style jsx>
        {`
          .image-content-element-container {
            background-color: ${contentElementBGColor(
              props.content.cfgBackgroundColor,
              props.settings
            )};

            /* TRM-99: This part fixes the "at the moment suboptimal cmsImage-Solution". 
            This styles .cms-managed-image inside the component with additional cssAttributes. (more than just width/&height) 
            The different components between contentElement and cmsImage handle such props like maxHeight/maxWidth differently. */
            :global(.cms-managed-image),
            :global(.no-image-selected) {
              max-height: ${maxHeights.mobile}px !important;
              // Tablet
              @media (min-width: ${globalSettings.responsive
                  .breakpointmobile}px) {
                max-height: ${maxHeights.tablet}px !important;
              }

              @media (min-width: ${globalSettings.responsive
                  .breakpointdesktop}px) {
                max-height: ${maxHeights.desktop}px !important;
              }

              @media (min-width: ${globalSettings.responsive.breakpoint2k}px) {
                max-height: ${maxHeights.wqhd}px !important;
              }
            }

            /* cfgImageObjectFit */
            :global(.cms-managed-image) {
              object-fit: ${getObjectFitValue(props.content.cfgImageObjectFit)};
            }

            :global(img) {
              /* cfgHasOverlay */
              filter: ${props.content.cfgHasOverlay
                ? "brightness(.7)"
                : "brightness(1)"};
            }
            /* cfgCaptionTextAlign */
            :global(.cms-content-caption),
            :global(.cms-content-caption-wrapper) {
              text-align: ${props.content.cfgCaptionTextAlign
                ? props.content.cfgCaptionTextAlign
                : "center"};
              font-size: ${props.settings.captionFontSize
                ? getRemValue(props.settings.captionFontSize) + "rem"
                : "unset"};
            }
          }
        `}
      </style>
    </>
  );
};

export default ImageContentElement;
