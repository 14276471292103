export default function CmsContentCaptionStyle() {
  return (
    <style jsx global>{`
    .cms-content-caption {
      background-color: transparent;
      width: 100%;
      border: none;
      display: block;
    }

    .cms-content-caption,
    .cms-content-caption-wrapper {
      word-break: break-word;
    }

    /* Richtext-like styling in Edit-View if no caption was entered */
    .cms-content-caption-wrapper {
      .no-caption {
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 1px;
        margin-bottom: 1px;
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;
      }
    }

      // center the caption through props.center
      // (if no cfg option is available)
      .cms-content-caption.center {
        text-align: center !important;
      }
      .cms-content-caption:focus {
        outline: none;
      }
    `}</style>
  );
}
