export default function ButtonOverlayStyle({ settings }) {
  return (
    <style jsx global>{`
      .icon-overlay {
        transition: background-color linear 300ms;
        pointer-events: none;
        width: 100%;
        height: 100%;
        position: absolute;

        .top-buttons {
          pointer-events: all;
          position: absolute;
          left: 100%;
          top: 100%;
          transform: translate(calc(-100% - 20px), calc(-100% - 10px));
          display: flex;
          z-index: 1000;
          :hover {
            cursor: pointer;
          }
          svg {
            -webkit-filter: drop-shadow(0px 0px 4px black);
            filter: drop-shadow(0px 0px 4px black);
          }

          .cms-popover-button-x {
            filter: unset;
          }
        }
        .upload-icon {
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 980;
          svg {
            opacity: 0;
            -webkit-filter: drop-shadow(0px 0px 4px black);
            filter: drop-shadow(0px 0px 4px black);
            transition: opacity linear 300ms;
          }
        }
      }
      .button-overlay-wrapper:hover {
        cursor: pointer;
        .icon-overlay {
          background-color: rgba(0, 0, 0, 0.5);
          .upload-icon {
            opacity: 1;
            svg {
              opacity: 1;
            }
          }
        }
      }
    `}</style>
  );
}
